var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_invoice_recurring_bap") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            "label-align": "right",
            form: _vm.form,
            "wrapper-col": { span: 12 },
            "label-col": { span: 10 }
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleFind.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.branch.label),
                        "label-align": "left",
                        "label-col": { span: 8 }
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingBranch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBranch(value)
                            },
                            change: _vm.handleChangeBranch
                          }
                        },
                        _vm._l(_vm.dataBranch, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.customerName.label),
                        "label-align": "left",
                        "label-col": { span: 8 }
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingCustomer,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCustomerName(value)
                            },
                            change: _vm.handleChangeCustomer
                          }
                        },
                        _vm._l(_vm.dataCustomer, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          data.fullName
                                            ? data.fullName.replace("null", "")
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        data.fullName
                                          ? data.fullName.replace("null", "")
                                          : "-"
                                      ) +
                                      " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.currency.label),
                        "label-align": "left",
                        "label-col": { span: 8 }
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.currency.decorator,
                              expression: "formRules.currency.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.currency.name,
                            placeholder: _vm.$t(
                              _vm.formRules.currency.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingCurrency,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCurrency(value)
                            },
                            change: _vm.handleChangeCurrency
                          }
                        },
                        _vm._l(_vm.dataCurrency, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.currencyCode } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s(data.currencyCode) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s(data.currencyCode) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.invoiceAr.label),
                        "label-align": "left",
                        "label-col": { span: 8 }
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.invoiceAr.decorator,
                              expression: "formRules.invoiceAr.decorator"
                            }
                          ],
                          attrs: {
                            loading: _vm.loading.invoiceAr,
                            "allow-clear": "",
                            "show-search": "",
                            "filter-option": false
                          },
                          on: { search: _vm.onSearchInvoiceAr }
                        },
                        _vm._l(_vm.optInvoiceAr, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.label } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.internalContractNo.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.internalContractNo.decorator,
                              expression:
                                "formRules.internalContractNo.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.internalContractNo.name,
                            placeholder: _vm.$t(
                              _vm.formRules.internalContractNo.placeholder
                            ),
                            mode: "multiple",
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingInternalContract,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getInternalContract(value)
                            },
                            change: _vm.handleChangeIC
                          }
                        },
                        _vm._l(_vm.dataInternalContract, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.documentNo } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.documentNo) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.documentNo) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.bastNumber.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.bastNumber.decorator,
                              expression: "formRules.bastNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.bastNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.bastNumber.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingBastNumber,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getBastNumber(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataBastNumber, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.bastNumber } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.bastNumber) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.bastNumber) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.billingPeriod.label)
                      }
                    },
                    [
                      _c("a-month-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.billingPeriod.decorator,
                            expression: "formRules.billingPeriod.decorator"
                          }
                        ],
                        attrs: {
                          format: _vm.DATE_FORMAT_MMM_YYYY,
                          "allow-clear": ""
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.byInvoice.label) } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.byInvoice.decorator,
                              expression: "formRules.byInvoice.decorator"
                            }
                          ],
                          attrs: { loading: _vm.loading.byInvoice }
                        },
                        _vm._l(_vm.optByInvoice, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleCancel } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.loadingFind
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  "data-source": _vm.dataSource,
                  columns: _vm.columnsTable,
                  pagination: {
                    showTotal: function(total) {
                      return _vm.$t("lbl_total_items", {
                        total: _vm.dtReport.totalElements
                      })
                    },
                    showSizeChanger: true,
                    pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                    current: _vm.pagination.page,
                    total: _vm.dtReport.totalElements
                  },
                  loading: _vm.loadingFind,
                  scroll: { y: 700 },
                  size: "small",
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                    onSelectAll: _vm.onSelectAll
                  }
                },
                on: { change: _vm.onChangeTable },
                scopedSlots: _vm._u([
                  {
                    key: "number",
                    fn: function(text) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm._f("qty")(text)))
                      ])
                    }
                  },
                  {
                    key: "date",
                    fn: function(text) {
                      return _c("span", {}, [
                        _vm._v(_vm._s(_vm._f("date")(text)))
                      ])
                    }
                  },
                  {
                    key: "nullable",
                    fn: function(text) {
                      return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                    }
                  },
                  {
                    key: "unitCode",
                    fn: function(text, record) {
                      return [
                        record.isUnitBackup
                          ? [
                              _c(
                                "a-tooltip",
                                { attrs: { title: _vm.$t("lbl_unit_backup") } },
                                [
                                  _c("a-icon", {
                                    attrs: {
                                      type: "interaction",
                                      theme: "twoTone",
                                      "two-tone-color": "#52c41a"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _c("span", { class: { "ml-1": record.isUnitBackup } }, [
                          _vm._v(_vm._s(record.unitCode))
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _vm.$can("read", "invoice-ar")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.download,
                            type: "primary"
                          },
                          on: { click: _vm.download }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                      )
                    : _vm._e(),
                  _vm.$can("read", "invoice-ar")
                    ? _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.viewInvoice }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_view_invoice")) + " ")]
                      )
                    : _vm._e(),
                  _vm.$can("create", "invoice-ar") ||
                  _vm.$can("update", "invoice-ar")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.generate
                          },
                          on: { click: _vm.validateGenerate }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_generate_invoice")) + " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }