






































































































































































































































































































import { SearchBuilder } from "@/builder";
import { debounce, debounceProcess } from "@/helpers/debounce";
import {
  useBlob,
  useContactListFindById,
  useFindMasterType,
  useInvoiceAR,
  useMapMasterTypeToOption,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  DATE_FORMAT_MMM_YYYY,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { ReqParamDownloadRecurring } from "@/models/interface/AccountReceivables.interface";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ICBillings } from "@/models/interface/invoice.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import {
  DataListInternalContract,
  DataResponseBastHasBeenCreated,
} from "@/models/interface/salesOrder.interface";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { contactServices } from "@/services/contact.service";
import { invoiceServices } from "@/services/invoice.service";
import { logisticServices } from "@/services/logistic.service";
import { salesOrderServices } from "@/services/salesorder.service";
import { settingsServices } from "@/services/settings.service";
import { SorterProps } from "@/types";
import { InvoiceIcBillingLineCreateDto } from "@interface/ic-billing";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment, { Moment } from "moment";
import { Component, Mixins } from "vue-property-decorator";

type Row = InvoiceIcBillingLineCreateDto & {
  key: string;
  customBastNo: string;
  contractPeriod: string;
};

@Component
export default class InvoicesRecuringBap extends Mixins(MNotificationVue) {
  DATE_FORMAT_MMM_YYYY = DATE_FORMAT_MMM_YYYY;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  dtReport: Pagination<InvoiceIcBillingLineCreateDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };
  pagination = {
    page: 1,
    limit: 10,
    sorts: "billingDate:asc,unitCode:asc",
  };
  form!: WrappedFormUtils;
  idBranch = "";
  idCustomer = "";
  currency = "";
  selectedRowKeys: Array<string> = [];
  dataBranch: Array<DataWarehouseBranch> = [];
  dataCustomer: Array<ContactData> = [];
  dataInternalContract: Array<DataListInternalContract> = [];
  dataBastNumber: Array<DataResponseBastHasBeenCreated> = [];
  dataCurrency: Array<DataMasterCurrency> = [];
  optByInvoice: Array<Option> = [];
  optInvoiceAr: Array<Option> = [];
  loadingBranch = false;
  loadingCustomer = false;
  loadingInternalContract = false;
  loadingBastNumber = false;
  loadingFind = false;
  loadingCurrency = false;
  dataSource: Array<Row> = [];
  columnsTable = [
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      sorter: true,
      width: 120,
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_bast_number"),
      dataIndex: "customBastNo",
      key: "customBastNo",
      scopedSlots: { customRender: "nullable" },
      sorter: true,
    },
    {
      title: this.$t("lbl_contract_period"),
      dataIndex: "contractPeriod",
      key: "contractPeriod",
      scopedSlots: { customRender: "nullable" },
      sorter: true,
    },
    {
      title: this.$t("lbl_billing_date"),
      dataIndex: "billingDate",
      key: "billingDate",
      width: 120,
      scopedSlots: { customRender: "date" },
      sorter: true,
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      width: 150,
      scopedSlots: { customRender: "nullable" },
      sorter: true,
    },
    {
      title: this.$t("lbl_internal_contract_number"),
      dataIndex: "internalContractNo",
      key: "internalContractNo",
      scopedSlots: { customRender: "nullable" },
      sorter: true,
    },
    {
      title: this.$t("lbl_ar_invoice_number"),
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      scopedSlots: { customRender: "nullable" },
      sorter: true,
    },
  ];
  formRules = {
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    internalContractNo: {
      label: "lbl_internal_contract_number",
      name: "internalContractNo",
      placeholder: "lbl_internal_contract_number",
      decorator: [
        "internalContractNo",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    bastNumber: {
      label: "lbl_bast_number",
      name: "bastNumber",
      placeholder: "lbl_bast_number",
      decorator: [
        "bastNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    currency: {
      label: "lbl_currency",
      name: "currency",
      placeholder: "lbl_currency",
      decorator: [
        "currency",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    yearPeriod: {
      label: "lbl_year_period",
      placeholder: "lbl_year_period",
    },
    billingPeriod: {
      label: "lbl_billing_period",
      placeholder: "lbl_billing_period",
      decorator: ["billingPeriod"],
    },
    byInvoice: {
      label: "lbl_by_invoice",
      placeholder: "lbl_by_invoice",
      decorator: [
        "byInvoice",
        {
          initialValue: "ALL",
        },
      ],
    },
    invoiceAr: {
      label: "lbl_invoice_ar_no",
      placeholder: "lbl_invoice_ar_no",
      decorator: ["invoiceArNumbers"],
    },
  };

  loading = {
    invoiceAr: false,
    generate: false,
    download: false,
    byInvoice: false,
  };

  queryParams = new RequestQueryParams();

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "recurringBap" });
  }

  created() {
    this.getCustomerName = debounceProcess(this.getCustomerName, 400);
    this.getInternalContract = debounceProcess(this.getInternalContract, 400);
    this.getBastNumber = debounceProcess(this.getBastNumber, 400);
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getCurrency = debounceProcess(this.getCurrency, 400);

    this.getBranch("");
    this.getCustomerName("");
    this.getCurrency("");
    this.fetchByInvoiceOption();
    this.fetchInvoiceAr(new RequestQueryParams());
  }

  onSelectChange(value: string[]): void {
    this.selectedRowKeys = value;
  }
  handleCancel() {
    this.dataSource = [];
    this.selectedRowKeys = [];
    this.form.resetFields();
  }
  handleChangeBranch(value) {
    this.form.setFieldsValue({ branch: value });
    this.idBranch = value;
    this.form.resetFields([
      "internalContractNo",
      "bastNumber",
      "invoiceArNumbers",
    ]);
    this.getInternalContract("");
    this.fetchInvoiceAr(new RequestQueryParams(this.buildQSearchInvoice()));
  }

  handleChangeCustomer(value = "") {
    this.form.setFieldsValue({ customerName: value });
    this.idCustomer = value;
    this.form.resetFields([
      "internalContractNo",
      "bastNumber",
      "invoiceArNumbers",
    ]);
    this.getInternalContract("");
    this.fetchInvoiceAr(new RequestQueryParams(this.buildQSearchInvoice()));
    this.getBastNumber();
  }

  handleChangeIC(value = []): void {
    this.form.setFieldsValue({ internalContractNo: value });
    this.form.resetFields(["bastNumber"]);
    this.getBastNumber("");
  }

  handleChangeCurrency(value) {
    this.form.setFieldsValue({ currency: value });
    this.currency = value;
    this.form.resetFields([
      "internalContractNo",
      "bastNumber",
      "invoiceArNumbers",
    ]);
    this.getInternalContract("");
    this.fetchInvoiceAr(new RequestQueryParams(this.buildQSearchInvoice()));
  }
  getCurrency(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (valueSearch) params.search = `currencyCode~${valueSearch}`;
    this.loadingCurrency = true;
    settingsServices
      .listOfMasterCurrency(params, "")
      .then(response => (this.dataCurrency = response.data))
      .finally(() => (this.loadingCurrency = false));
  }
  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loadingBranch = false));
  }
  getCustomerName(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: "customer~true_AND_active~true",
    };
    if (valueSearch)
      params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
    this.loadingCustomer = true;
    contactServices
      .listContactData(params)
      .then(response => (this.dataCustomer = response.data))
      .finally(() => (this.loadingCustomer = false));
  }
  getInternalContract(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (valueSearch) params.search = `documentNo~*${valueSearch}*`;
    if (this.idBranch) {
      if (params.search)
        params.search += `_AND_branch.secureId~${this.idBranch}`;
      else params.search = `branch.secureId~${this.idBranch}`;
    }
    if (this.idCustomer) {
      if (params.search)
        params.search += `_AND_customer.secureId~${this.idCustomer}`;
      else params.search = `customer.secureId~${this.idCustomer}`;
    }
    if (this.currency) {
      if (params.search)
        params.search += `_AND_currency.currencyCode~${this.currency}`;
      else params.search = `currency.currencyCode~${this.currency}`;
    }
    this.loadingInternalContract = true;
    salesOrderServices
      .getListInternalContractForRecurring(params)
      .then(response => (this.dataInternalContract = response.data))
      .finally(() => (this.loadingInternalContract = false));
  }
  getBastNumber(valueSearch = "") {
    const params = new RequestQueryParams();
    const queries: string[] = [];
    const icNo: Array<string> = this.form.getFieldValue("internalContractNo");
    const customerId: string = this.form.getFieldValue("customerName");

    if (valueSearch) {
      queries.push(
        new SearchBuilder()
          .push(["documentNo", valueSearch], { like: "both" })
          .build()
      );
    }

    if (icNo && icNo.length) {
      const ics: string = icNo
        .map<string>(item => "internalContract.documentNo~" + item)
        .join("_AND_");
      queries.push(ics);
    }

    if (customerId) {
      queries.push(
        new SearchBuilder()
          .push(["internalContract.customer.secureId", customerId])
          .build()
      );
    }

    params.search = queries.join(new SearchBuilder().AND);

    this.loadingBastNumber = true;
    salesOrderServices
      .getListBastHasBeenCreated(params)
      .then(response => {
        this.dataBastNumber = response.data;
      })
      .finally(() => {
        this.loadingBastNumber = false;
      });
  }
  handleViewInvoices(icBilling) {
    this.form.validateFields(err => {
      if (err) return;
      if (this.selectedRowKeys.length === 1) {
        this.$router.push(
          `/accountreceivables/invoices/detail/recurring/${icBilling[0].invoiceId}`
        );
      } else {
        this.$notification.error({
          message: "Error!",
          description: `you can only choose 1 data to view invoice`,
        });
      }
    });
  }
  viewInvoice(): void {
    if (this.selectedRowKeys.length < 1) {
      this.$notification.error({
        message: "Error",
        description: "Select Data First to Generate Payment",
      });
    } else {
      const icBilling: ICBillings[] = [];
      const dataUsed: string[] = []; // bast No
      let flag = false;
      this.dataSource.forEach(dataForeach => {
        if (this.selectedRowKeys.includes(dataForeach.key)) {
          icBilling.push({
            bastNo: dataForeach.bastNo,
            used: dataForeach.used,
            billingNo: dataForeach.billingNo,
            rentPeriod: dataForeach.rentPeriod,
            startDate: dataForeach.startDate,
            endDate: dataForeach.endDate,
            billingDate: dataForeach.billingDate,
            internalContractNo: dataForeach.internalContractNo,
            invoiceNo: dataForeach.invoiceNo,
            invoiceId: dataForeach.invoiceId,
          });
        }
        if (
          !dataForeach.used &&
          this.selectedRowKeys.includes(dataForeach.key)
        ) {
          flag = true;
          dataUsed.push(dataForeach.bastNo);
        }
      });

      if (flag) {
        this.$notification.error({
          message: "Error!",
          description: `data with bast number: ${dataUsed.join(
            ", "
          )} has not been generated, please select another data`,
        });
      } else {
        this.handleViewInvoices(icBilling);
      }
    }
  }

  goToArCreate(recurringIds: Array<string>): void {
    this.$router.push({
      name: "account-receivable.invoice.recurring.create",
      query: {
        recurringIds: recurringIds.length ? recurringIds : null,
      },
    });
  }

  validateGenerate(): void {
    if (!this.selectedRowKeys.length) {
      this.showNotifWarning("lbl_no_document_selected");
      return;
    }

    this.loading.generate = true;
    invoiceServices
      .validateGenerateBap(this.selectedRowKeys)
      .then(response => {
        const isValid: boolean = response.length === 0;
        if (isValid) {
          this.goToArCreate(this.selectedRowKeys);
        } else {
          const bastNumbers = response.map<string>(item => item.bastNumber);
          this.showNotifWarning("notif_invalid_generate_bast", {
            data: bastNumbers.join(", "),
          });
        }
      })
      .finally(() => {
        this.loading.generate = false;
      });
  }

  fetchData(res): void {
    const params = new RequestQueryParams();
    params.billingPeriod = res.billingPeriod
      ? (res.billingPeriod as Moment).format(DATE_FORMAT_MMM_YYYY)
      : null;
    params.recurringInvoiceArStatus = res.byInvoice;
    params.arInvoiceNo = res.invoiceArNumbers ?? null;
    params.branchWarehouseId = res.branch;
    params.customerId = res.customerName;
    params.internalContractNo = (res.internalContractNo || []).join(",");
    params.currencyCode = res.currency;
    params.bastNo = res.bastNumber ?? null;
    params.page = this.pagination.page - 1;
    params.limit = this.pagination.limit;
    params.sorts = this.pagination.sorts;

    this.queryParams = { ...params };

    this.loadingFind = true;
    invoiceServices
      .getListInvoiceRecurringBap(params)
      .then(response => {
        this.dtReport = response;
        this.queryParams.limit = response.totalElements;
        this.dataSource = this.dtReport.data.map<Row>(item => {
          const start = item.startDate
            ? moment(item.startDate).format(DEFAULT_DATE_FORMAT)
            : "";
          const end = item.endDate
            ? moment(item.endDate).format(DEFAULT_DATE_FORMAT)
            : "";
          return {
            ...item,
            key: item.recurringId,
            customBastNo: item.bastReplacementNo || item.bastNo || "-",
            contractPeriod: `${start}-${end}`,
          };
        });
      })
      .finally(() => (this.loadingFind = false));
  }

  handleFind(): void {
    this.form.validateFields((err, res) => {
      if (err) return;
      this.pagination.page = 1;

      this.fetchData(res);
    });
  }

  async buildParamsDownload(): Promise<ReqParamDownloadRecurring> {
    const {
      customerName,
      branch: branchId,
      internalContractNo = [],
      bastNumber,
      currency,
      byInvoice,
      invoiceArNumbers,
      billingPeriod,
    } = this.form.getFieldsValue();
    const company = this.$store.state.authStore.authData.companyName;
    const year = billingPeriod
      ? moment(billingPeriod).format(DATE_FORMAT_MMM_YYYY)
      : null;
    const date = year || "ALL";
    let customer = "ALL";

    if (customerName) {
      const response = await useContactListFindById(customerName);
      customer = response?.fullName ?? "ALL";
    }

    const params: ReqParamDownloadRecurring = {
      internalContractNo: internalContractNo.join(",") || "",
      recurringInvoiceArStatus: byInvoice,
      arInvoiceNo: invoiceArNumbers,
      branchWarehouseId: branchId,
      customerId: customerName,
      currencyCode: currency,
      bastNo: bastNumber,
      params: [company, date, customer].join(","),
    };

    if (year) {
      params.billingPeriod = year;
    }

    return params;
  }

  async download(): Promise<void> {
    try {
      const { toDownload } = useBlob();
      const { downloadRecurring } = useInvoiceAR();
      this.loading.download = true;
      const params = await this.buildParamsDownload();
      const blob = await downloadRecurring(params);
      toDownload(blob, "invoice_recurring_bap.xlsx");
    } catch (error) {
      this.showNotifError("notif_download_error");
    } finally {
      this.loading.download = false;
    }
  }

  fetchByInvoiceOption(): void {
    this.loading.byInvoice = true;
    useFindMasterType("RECURRING_INVOICE_AR_STATUS")
      .then(response => {
        this.optByInvoice = useMapMasterTypeToOption(response);
      })
      .finally(() => {
        this.loading.byInvoice = false;
      });
  }

  buildQSearchInvoice(docNumber = ""): string {
    const {
      branch: branchId,
      currency,
      customerName: customerId,
    } = this.form.getFieldsValue();
    const builder = new SearchBuilder();
    const q: Array<string> = [];

    if (customerId) {
      q.push(builder.push(["customer.secureId", customerId]).build());
      builder.destroy();
    }

    if (branchId) {
      q.push(builder.push(["branchWarehouse.secureId", branchId]).build());
      builder.destroy();
    }

    if (currency) {
      q.push(builder.push(["priceCurrency.currencyCode", currency]).build());
      builder.destroy();
    }

    if (docNumber) {
      q.push(builder.push(["documentNumber", docNumber]).build());
      builder.destroy();
    }

    return q.join(builder.AND);
  }

  fetchInvoiceAr(params: RequestQueryParamsModel): void {
    const { findAll, toOptions } = useInvoiceAR();
    this.loading.invoiceAr = true;
    findAll(params)
      .then(response => {
        this.optInvoiceAr = toOptions(response.data);
      })
      .finally(() => {
        this.loading.invoiceAr = false;
      });
  }

  onSearchInvoiceAr(search = ""): void {
    const params = new RequestQueryParams();
    params.search = this.buildQSearchInvoice(search);
    debounce(() => {
      this.fetchInvoiceAr(params);
    });
  }

  getSortField(columnKey: string): string {
    if (columnKey === "customBastNo") {
      return "bastNo";
    } else if (columnKey === "contractPeriod") {
      return "contractPeriodStartDate";
    } else if (columnKey === "branchName") {
      return "branchWarehouse";
    } else if (columnKey === "internalContractNo") {
      return "internalContract.documentNo";
    } else if (columnKey === "invoiceNo") {
      return "invoiceARList.invoiceAR.documentNumber";
    }
    return columnKey;
  }

  onChangeTable(
    pagination: {
      total: number;
      current: number;
      pageSize: number;
    },
    _filter,
    sorter: SorterProps
  ): void {
    const { columnKey, order } = sorter;

    this.pagination.sorts = "billingDate:asc,unitCode:asc";
    if (order === "ascend") {
      this.pagination.sorts = `${this.getSortField(columnKey)}:asc`;
    } else if (order === "descend") {
      this.pagination.sorts = `${this.getSortField(columnKey)}:desc`;
    }

    this.pagination.page = pagination.current;
    if (this.pagination.limit !== pagination.pageSize) {
      this.pagination.page = 1;
    }

    this.pagination.limit = pagination.pageSize;
    this.fetchData(this.form.getFieldsValue());
  }

  onSelectAll(selected: boolean): void {
    if (!selected) {
      this.selectedRowKeys = [];
      return;
    }
    this.queryParams.page = 0;

    this.loadingFind = true;
    invoiceServices
      .getListInvoiceRecurringBap(this.queryParams)
      .then(({ data }) => {
        this.selectedRowKeys = data.map(item => item.recurringId);
      })
      .finally(() => {
        this.loadingFind = false;
      });
  }
}
